"use client";

import { useEffect } from "react";

import { loadInitialBookmarksOnce } from "@/lib/stores/bookmarksStore";

/**
 * Helper component to initialise the Bookmarks store, only when it is safe to
 * do so without triggering React hydration errors.
 *
 * This must be included at top level of site, ideally root layout.
 */
export function BookmarksLoadFromServerHelper() {
	useEffect(loadInitialBookmarksOnce, []);

	// This component is just a helper, it doesn't render anything
	return null;
}
