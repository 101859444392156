import type { Theme } from '../helpers/theme';
import * as dataLayer from './dataLayer';

export function themeEvent(theme: Theme): void {
  dataLayer.push({
    event: 'custom.theme.click',
    group: 'theme_change',
    label: theme,
  });
}
