import { MonaFomaLogo } from "@/svg";
import NavigationItem from "../../../navigationItem/NavigationItem";
import styles from "./HomeNavigationItem.module.scss";

export default function HomeNavigationItem() {
	return (
		<NavigationItem href="/" title="Mona Foma" variant="logo">
			<MonaFomaLogo className={styles.logo} />
		</NavigationItem>
	);
}
