import { ProgramPhase } from "@/sanity/schemas/vendored/enums";
import NavigationItem from "../../navigationItem/NavigationItem";

import styles from "./TicketNavigationItem.module.scss";

export interface TicketNavigationItemProps {
	href?: string;
	currentPhase?: ProgramPhase;
}

export default function TicketNavigationItem({
	href,
	currentPhase = ProgramPhase.EarlyAnnounce,
}: TicketNavigationItemProps) {
	// Tickets link is enabled during on-sale phases
	if (
		href !== undefined &&
		currentPhase >= ProgramPhase.GeneralOnsale &&
		currentPhase < ProgramPhase.Archive
	) {
		return (
			<NavigationItem href={href} className={styles.enabled}>
				Tickets
			</NavigationItem>
		);
	}

	// Tickets link is disabled for all other phases
	return (
		<NavigationItem href={href ?? "/"} className={styles.disabled} disabled>
			{currentPhase < ProgramPhase.Archive && <div>Tickets</div>}
		</NavigationItem>
	);
}
