import { ProgramPhase } from "@/sanity/schemas/vendored/enums";
import { notFound } from "next/navigation";

export function phaseMatch(
	current: ProgramPhase | undefined,
	phases: ProgramPhase | Array<ProgramPhase>,
): boolean {
	if (current === undefined) {
		return false;
	}

	if (!(phases instanceof Array)) {
		return current === phases;
	}

	return phases.includes(current);
}

export function beforePhase(
	current: ProgramPhase | undefined,
	minimum: ProgramPhase,
	onBefore?: (minimum: ProgramPhase) => any,
): boolean {
	if (current === undefined || current < minimum) {
		onBefore && onBefore(minimum);

		return true;
	}

	return false;
}

export function notFoundBeforePhase(
	current: ProgramPhase | undefined,
	minimum: ProgramPhase,
): boolean {
	return beforePhase(current, minimum, notFound);
}

/**
 * Determine whether the current phase is within an optional range.
 *
 * Optional minimum and maximum are inclusive (i.e. minimum <= current <= maximum)
 *
 * Defaults to
 *
 * @param current
 * @param minimum
 * @param maximum
 */
export function withinPhaseRange(
	current: ProgramPhase | undefined,
	minimum?: ProgramPhase,
	maximum?: ProgramPhase,
): boolean {
	// If current phase is not defined, default to `true` (consider requiring current)
	if (current === undefined) {
		return true;
	}

	// When both are defined, must fall in inclusive range
	if (minimum !== undefined && maximum !== undefined) {
		return minimum <= current && current <= maximum;
	}

	// If only minimum defined, must match or exceed minimum
	if (minimum !== undefined) {
		return minimum <= current;
	}

	// If only maximum defined, must not exceed maximum
	if (maximum !== undefined) {
		return current <= maximum;
	}

	// Default to true if no range defined
	return true;
}
