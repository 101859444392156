export const MONAIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 82 19"
			className={className}
		>
			<g fill="white" clipPath="url(#a)">
				<path d="M26.21 9.78003c0 3.46997 2.58 6.29997 6.04 6.29997 3.43 0 6.01-2.83 6.01-6.29997 0-3.5-2.58-6.33-6.01-6.33-3.46 0-6.04 2.83-6.04 6.33Zm-3.19 0c0-5.1 3.92-9.250001 9.23-9.250001 5.28 0 9.23 4.150001 9.23 9.250001C41.48 14.85 37.53 19 32.25 19c-5.31 0-9.23-4.15-9.23-9.21997ZM73.81 7.95001l2.2 4.92999h-4.4l2.2-4.92999Zm-.46-6.88-1.3 2.93L68.66 11.64l-3.04 6.8h3.51l1.12-2.53h7.09l1.13 2.53H82L74.31 1.10001h-.06l-.01-.03h-.89ZM58.43 1.07001V12.22L47.28 1.07001h-1.14v.03h-.02V18.43h3.21V7.63001l9.1 9.09999v1.71h3.19V1.07001h-3.19ZM17.31 1.09003l-8.07 8.07-8.06-8.05H0V18.43h3.16V7.50003L9.26 13.59l6.02-6.01997V18.4h3.15V1.09003h-1.12Z" />
			</g>
			<defs>
				<clipPath id="a">
					<path fill="white" d="M0 0h82v19H0z" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const ChevronDownIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="none"
			className={className}
		>
			<path d="M20 9L12 17L4 9" strokeWidth="1.5" strokeLinecap="square" />
		</svg>
	);
};

export const ChevronLeftIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 28 28"
			fill="none"
			className={className}
		>
			<path
				d="M17.1984 22.9316L8.02595 13.7592L17.1984 4.58681"
				strokeWidth="1.71983"
				strokeLinecap="square"
			/>
		</svg>
	);
};

export const ChevronRightIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 28 28"
			fill="none"
			className={className}
		>
			<path
				d="M10.319 4.58594L19.4914 13.7583L10.319 22.9308"
				strokeWidth="1.71983"
				strokeLinecap="square"
			/>
		</svg>
	);
};

export const MinusCircleIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 40 41"
			className={className}
		>
			<path
				d="M27.071 20.5004H12.9289M35.4166 20.4997C35.4166 29.0141 28.5143 35.9163 19.9999 35.9163C11.4855 35.9163 4.58325 29.0141 4.58325 20.4997C4.58325 11.9853 11.4855 5.08301 19.9999 5.08301C28.5143 5.08301 35.4166 11.9853 35.4166 20.4997Z"
				strokeWidth="1.5"
				strokeLinecap="square"
			/>
		</svg>
	);
};

export const PlusCircleIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 41 41"
			className={className}
		>
			<path
				d="M27.7479 20.5003H13.2522M20.5001 27.7482V13.2525M36.3022 20.5003C36.3022 29.2276 29.2273 36.3024 20.5001 36.3024C11.7728 36.3024 4.698 29.2276 4.698 20.5003C4.698 11.7731 11.7728 4.69824 20.5001 4.69824C29.2273 4.69824 36.3022 11.7731 36.3022 20.5003Z"
				strokeWidth="1.5"
				strokeLinecap="square"
			/>
		</svg>
	);
};

export const CheckboxCircleIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 40 40"
			className={className}
		>
			<circle cx="20" cy="20" r="19.5" stroke="white" />
		</svg>
	);
};

export const ChainLinkIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="none"
			className={className}
		>
			<path d="M6.75 11.25H6V12.75H6.75V11.25ZM17.25 12.75H18V11.25H17.25V12.75ZM22.25 6.75H23V6H22.25V6.75ZM22.25 17.25V18H23V17.25H22.25ZM15.75 16.5H15V18H15.75V16.5ZM15.75 6H15V7.5H15.75V6ZM1.75 6.75V6H1V6.75H1.75ZM1.75 17.25H1V18H1.75V17.25ZM8.25 18H9V16.5H8.25V18ZM8.25 7.5H9V6H8.25V7.5ZM6.75 12.75H17.25V11.25H6.75V12.75ZM21.5 6.75V17.25H23V6.75H21.5ZM22.25 16.5H15.75V18H22.25V16.5ZM15.75 7.5H22.25V6H15.75V7.5ZM1 6.75V17.25H2.5V6.75H1ZM1.75 18H8.25V16.5H1.75V18ZM8.25 6H1.75V7.5H8.25V6Z" />
		</svg>
	);
};

export const EnvelopeIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="none"
			className={className}
		>
			<path d="M21.25 4.75H22V4H21.25V4.75ZM21.25 19.25V20H22V19.25H21.25ZM2.75 19.25H2V20H2.75V19.25ZM2.75 4.75V4H2V4.75H2.75ZM12 13L11.5251 13.5805L12 13.969L12.4749 13.5805L12 13ZM20.5 4.75V19.25H22V4.75H20.5ZM21.25 18.5H2.75V20H21.25V18.5ZM3.5 19.25V4.75H2V19.25H3.5ZM2.75 5.5H21.25V4H2.75V5.5ZM12.4749 12.4195L3.47493 5.0559L2.52507 6.21683L11.5251 13.5805L12.4749 12.4195ZM20.5251 5.0559L11.5251 12.4195L12.4749 13.5805L21.4749 6.21683L20.5251 5.0559Z" />
		</svg>
	);
};

export const CartIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 94 94"
			className={className}
		>
			<path
				fill="black"
				d="M37.6701 84.2901c0 5.13-4.2 9.33-9.33 9.33s-9.33-4.2-9.33-9.33 4.2-9.33 9.33-9.33 9.33 4.21 9.33 9.33Zm37.31-9.32c-5.13 0-9.33 4.2-9.33 9.33s4.2 9.33 9.33 9.33 9.33-4.2 9.33-9.33c-.01-5.13-4.2-9.33-9.33-9.33Zm-41.5-23.32h34.51c3.73 0 6.53-1.87 7.93-4.66l16.78-30.31c.93-.93.93-1.4.93-2.33 0-2.8-1.87-4.65999-4.66-4.65999h-69.02l-4.2-9.330003H.370117V9.69011H9.70012L26.4901 45.1301l-6.53 11.19c-.47 1.4-.93 3.26-.93 4.66 0 5.13 4.2 9.33 9.33 9.33h55.94v-9.33h-54.09c-.47 0-.93-.47-.93-.93v-.47l4.2-7.93Z"
			/>
		</svg>
	);
};
