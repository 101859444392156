import { Close, Hamburger } from "@/svg";
import styles from "./HamburgerButton.module.scss";

export interface HamburgerButtonProps {
	open?: boolean;
	onClick?: () => any;
}

export default function HamburgerButton({
	open,
	onClick,
}: HamburgerButtonProps) {
	return (
		<button
			aria-label={`${open ? "Hide" : "Show"} mobile menu`}
			onClick={onClick}
			className={open ? styles.open : styles.closed}
			title={open ? "Close menu" : "Open menu"}
		>
			{open ? (
				<Close className={styles.icon} />
			) : (
				<Hamburger className={styles.icon} />
			)}
		</button>
	);
}
