import type { PhaseProps } from "@/components/types/PhaseProps";
import {
	PROGRAM_HOBART_SLUG,
	PROGRAM_HOBART_TITLE,
	PROGRAM_LAUNCESTON_SLUG,
	PROGRAM_LAUNCESTON_TITLE,
	PROGRAM_SLUG,
	TICKETS_BUNDLE_SLUG,
	TICKETS_BUNDLE_TITLE,
	TICKETS_SLUG,
	TICKETS_TRANSPORT_SLUG,
	TICKETS_TRANSPORT_TITLE,
} from "@/lib/config/staticRoutes";
import { slugsPath } from "@/lib/helpers/paths";
import { ProgramPhase } from "@/sanity/schemas/vendored/enums";
import NavigationDivider from "../../navigationDivider/NavigationDivider";
import SecondaryNavigationItem from "../../navigationItem/SecondaryNavigationItem";

export interface ticketMenuItemsProps extends PhaseProps {
	dividers?: boolean;
}

const ticketMenuItems = [
	<SecondaryNavigationItem
		key={`${PROGRAM_SLUG}-${TICKETS_BUNDLE_SLUG}`}
		href={slugsPath(PROGRAM_SLUG, TICKETS_BUNDLE_SLUG)}
	>
		{`${TICKETS_BUNDLE_TITLE}s`}
	</SecondaryNavigationItem>,
	<NavigationDivider
		key={`${TICKETS_SLUG}-${PROGRAM_HOBART_SLUG}-divider`}
		variant="flower"
	/>,
	<SecondaryNavigationItem
		key={`${TICKETS_SLUG}-${PROGRAM_HOBART_SLUG}`}
		href={slugsPath(TICKETS_SLUG, PROGRAM_HOBART_SLUG)}
	>
		{PROGRAM_HOBART_TITLE}
	</SecondaryNavigationItem>,
	<NavigationDivider
		key={`${TICKETS_SLUG}-${PROGRAM_HOBART_SLUG}-divider`}
		variant="sunburst"
	/>,
	<SecondaryNavigationItem
		key={`${TICKETS_SLUG}-${PROGRAM_LAUNCESTON_SLUG}`}
		href={slugsPath(TICKETS_SLUG, PROGRAM_LAUNCESTON_SLUG)}
	>
		{PROGRAM_LAUNCESTON_TITLE}
	</SecondaryNavigationItem>,
	<NavigationDivider
		key={`${TICKETS_SLUG}-${PROGRAM_LAUNCESTON_SLUG}-divider`}
		variant="flower"
	/>,
	<SecondaryNavigationItem
		key={`${TICKETS_SLUG}-${TICKETS_TRANSPORT_SLUG}`}
		href={slugsPath(TICKETS_SLUG, TICKETS_TRANSPORT_SLUG)}
	>
		{TICKETS_TRANSPORT_TITLE}
	</SecondaryNavigationItem>,
];

export default function TicketMenuItems({
	phase = ProgramPhase.BrandAnnounce,
}: ticketMenuItemsProps) {
	// Only show ticket sub-menu items if we're before or after on-sale phases
	if (phase >= ProgramPhase.GeneralOnsale && phase < ProgramPhase.Archive) {
		return ticketMenuItems;
	}

	return null;
}
