"use client";

import { CartLink } from "@/components/tickets/CartLink";
import type { PhaseProps } from "@/components/types/PhaseProps";
import {
	ACCESSIBILITY_SLUG,
	ACCESSIBILITY_TITLE,
	INFO_SLUG,
	INFO_TITLE,
	PROGRAM_SLUG,
	PROGRAM_TITLE,
	SEARCH_SLUG,
	SEARCH_TITLE,
	SUBSCRIBE_SLUG,
	SUBSCRIBE_TITLE,
} from "@/lib/config/staticRoutes";
import { slugsPath } from "@/lib/helpers/paths";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import TicketNavigationItem from "../common/items/TicketNavigationItem";
import HomeNavigationItem from "../common/items/homeNavigationItem/HomeNavigationItem";
import ProgramMenuItems from "../common/menus/ProgramMenuItems";
import TicketMenuItems from "../common/menus/TicketMenuItems";
import NavigationItem from "../navigationItem/NavigationItem";
import NavigationItemGroup from "../navigationItemGroup/NavigationItemGroup";
import NavigationRow from "../navigationRow/NavigationRow";
import styles from "./MobileMenu.module.scss";
import HamburgerButton from "./hamburgerButton/HamburgerButton";

export interface MobileMenuProps extends PhaseProps {
	/** Whether to include the winter feast sub menu */
	feastSubMenu?: boolean;
	/** Whether to enable ticket links */
	ticketsHref?: string;
}

export default function MobileMenu({
	ticketsHref,
	phase,
	feastSubMenu = false,
}: MobileMenuProps) {
	const [open, setOpen] = useState(false);
	const currentPath = usePathname();

	// Close menu when router path updates (i.e. this includes all navigation)
	useEffect(() => setOpen(false), [currentPath]);

	return (
		<>
			<NavigationRow label="Mobile navigation bar">
				<li>
					<HamburgerButton open={open} onClick={() => setOpen(!open)} />
				</li>
				<HomeNavigationItem />

				<CartLink currentPhase={phase} />
			</NavigationRow>
			{open && (
				<nav className={styles.openNav} role="dialog" aria-modal>
					<NavigationItemGroup>
						<TicketNavigationItem href={ticketsHref} currentPhase={phase} />
						<TicketMenuItems phase={phase} />
					</NavigationItemGroup>
					<NavigationItemGroup>
						<NavigationItem href={slugsPath(PROGRAM_SLUG)}>
							{PROGRAM_TITLE}
						</NavigationItem>
						<ProgramMenuItems phase={phase} />
					</NavigationItemGroup>
					<NavigationItem href={slugsPath(INFO_SLUG)}>
						{INFO_TITLE}
					</NavigationItem>
					<NavigationItem href={slugsPath(ACCESSIBILITY_SLUG)}>
						{ACCESSIBILITY_TITLE}
					</NavigationItem>
					<NavigationItem href={slugsPath(SEARCH_SLUG)}>
						{SEARCH_TITLE}
					</NavigationItem>
					<NavigationItem href={slugsPath(SUBSCRIBE_SLUG)}>
						{SUBSCRIBE_TITLE}
					</NavigationItem>
				</nav>
			)}
		</>
	);
}
