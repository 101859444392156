"use client";

import { useTheme } from "@/components/context/ThemeContext";
import { themeEvent } from "@/lib/analytics/themeEvent";
import { THEME_DARK, THEME_LIGHT, Theme } from "@/lib/helpers/theme";
import { LightDarkCurve } from "@/svg";
import styles from "./ThemeToggle.module.scss";

export default function ThemeToggle() {
	const { theme, setTheme } = useTheme();

	const themePrompt = `Toggle site theme${
		theme ? ` to ${toggleTheme(theme)}` : ""
	}`;

	return (
		<button
			aria-label={themePrompt}
			title={themePrompt}
			className={styles.button}
			onClick={() => {
				const incomingTheme: Theme = toggleTheme(theme);

				themeEvent(incomingTheme);
				setTheme(incomingTheme);
			}}
		>
			<LightDarkCurve className={styles.icon} />
		</button>
	);
}

function toggleTheme(theme?: Theme): Theme {
	switch (theme) {
		case THEME_DARK:
			return THEME_LIGHT;
		case THEME_LIGHT:
		case undefined:
		default:
			return THEME_DARK;
	}
}
