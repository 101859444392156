import { clientEnv } from "@/lib/clientSide/env.client.mjs";

/**
 * Handle
 *
 * @param custom
 * @param standard
 * @returns
 */
export function warnUnsafeAssertion(standard: string, custom?: string) {
	if (custom) {
		return console.warn(custom);
	}

	if (clientEnv.LOG_LEVEL === "DEBUG") {
		console.warn(standard);
	}
}
