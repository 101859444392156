import { z } from "zod";

import { create } from "zustand";
import { PersistStorage, StorageValue, persist } from "zustand/middleware";

import throttle from "lodash/throttle";

import {
	deleteBookmarks,
	loadBookmarks,
	saveBookmarks,
} from "@/lib/stores/bookmarksServerActions";
import {
	WishlistIdentifierOrIdentifiers,
	WishlistSavedStatus,
} from "@/types/Wishlist";

const BookmarkSchema = z.object({
	id: z.string().nullish(),
	notes: z.string().nullish(),
});

export const BookmarksSchema = z
	.array(BookmarkSchema)
	.describe("BookmarksSchema");

export const BookmarksSessionSchema = z
	.object({
		bookmarks: BookmarksSchema,
	})
	.describe("BookmarksSessionSchema");

export type Bookmark = z.infer<typeof BookmarkSchema>;
export type Bookmarks = z.infer<typeof BookmarksSchema>;

interface BookmarksStore {
	bookmarks: Bookmarks | null;
	isInitialLoadComplete: boolean;

	actions: {
		isBookmarkedStatus: (
			bookmarkIds: WishlistIdentifierOrIdentifiers,
		) => WishlistSavedStatus;
		addBookmarks: (bookmarkIds: WishlistIdentifierOrIdentifiers) => void;
		updateBookmark: (
			bookmarkId: string,
			{ title, notes }: { title?: string; notes?: string },
		) => void;
		removeBookmarks: (bookmarkIds: WishlistIdentifierOrIdentifiers) => void;
		toggleBookmarks: (bookmarkIds: WishlistIdentifierOrIdentifiers) => void;
		resetBookmarks: (bookmarks?: Bookmarks) => void;
	};
}

/**
 * A Zustand `persist` storage implementation that syncs our store's state to
 * and from the server using server actions.
 *
 * https://docs.pmnd.rs/zustand/integrations/persisting-store-data
 */
const SyncWithServerStorage: PersistStorage<BookmarksStore> = {
	// Read bookmarks from server; trigger with `loadInitialBookmarksOnce()`
	getItem: async (name: string): Promise<StorageValue<BookmarksStore>> => {
		return {
			state: {
				bookmarks: (await loadBookmarks()) || [],
				isInitialLoadComplete: true,
			},
		} as StorageValue<BookmarksStore>;
	},

	// Write bookmarks to server with throttling to avoid excessive writes;
	// triggered auomatically on state changes
	setItem: throttle<PersistStorage<BookmarksStore>["setItem"]>(
		async (name: string, value: StorageValue<BookmarksStore>) => {
			await saveBookmarks(value.state.bookmarks ?? []);
		},
		// Throttle to no more than one write per 2 seconds
		2_000,
	),

	// Delete bookmarks on server; I'm not sure what triggers this?
	removeItem: async (name: string): Promise<void> => {
		await deleteBookmarks();
	},
};

/**
 * Load initial bookmarks data from server using Zustand's persist middleware
 * hydration mechanism, but only once.
 *
 * This must be called via a React `useEffect` hook to avoid React errors.
 */
export function loadInitialBookmarksOnce() {
	if (!useBookmarksStore.persist.hasHydrated()) {
		useBookmarksStore.persist.rehydrate();
	}
}

function findBookmarkAndIndex(
	bookmarks: Bookmarks | null,
	bookmarkId: string,
): [Bookmark | undefined, number] {
	const index = bookmarks?.findIndex((bm) => bm.id === bookmarkId) ?? -1;
	const bookmark = bookmarks && index >= 0 ? bookmarks[index] : undefined;
	return [bookmark, index];
}

// Define store but don't export it, export more specific hooks below
const useBookmarksStore = create<BookmarksStore>()(
	persist(
		(set, get) => ({
			bookmarks: null,

			isInitialLoadComplete: false,

			actions: {
				// Return:
				// - true if all bookmarkIds are bookmarked
				// - false if none of the bookmarkIds are bookmarked
				// - 'partial' if some of the bookmarkIds are bookmarked
				isBookmarkedStatus: (bookmarkIds: WishlistIdentifierOrIdentifiers) => {
					const bookmarkIdsArray = Array.isArray(bookmarkIds)
						? bookmarkIds
						: [bookmarkIds];

					const bookmarks = get().bookmarks ?? [];

					const countBookmarkedIds = bookmarkIdsArray.filter((bookmarkId) =>
						bookmarks.find(({ id }) => id === bookmarkId),
					).length;

					if (countBookmarkedIds >= bookmarkIdsArray.length) {
						return true;
					} else if (countBookmarkedIds > 0) {
						return "partial";
					} else {
						return false;
					}
				},

				addBookmarks: (bookmarkIds: WishlistIdentifierOrIdentifiers) => {
					const bookmarks = get().bookmarks ?? [];

					bookmarkIds = Array.isArray(bookmarkIds)
						? bookmarkIds
						: [bookmarkIds];
					const toAdd = bookmarkIds.map((id) => ({ id }));

					set({ bookmarks: [...toAdd, ...bookmarks] });
				},

				updateBookmark: (bookmarkId: string, { notes }: { notes?: string }) => {
					const bookmarks = get().bookmarks ?? [];

					const [bookmark, index] = findBookmarkAndIndex(bookmarks, bookmarkId);
					if (bookmark) {
						bookmarks[index] = {
							...bookmark,
							notes: notes ?? bookmark.notes,
						};

						set({ bookmarks: [...bookmarks] });
					}
				},

				removeBookmarks: (bookmarkIds: WishlistIdentifierOrIdentifiers) => {
					const bookmarks = get().bookmarks ?? [];

					bookmarkIds = Array.isArray(bookmarkIds)
						? bookmarkIds
						: [bookmarkIds];
					const bookmarksWithoutIds = bookmarks.filter(
						({ id }) => !id || !bookmarkIds.includes(id),
					);

					set({
						bookmarks: bookmarksWithoutIds,
					});
				},

				toggleBookmarks: (bookmarkIds: WishlistIdentifierOrIdentifiers) => {
					const status = get().actions.isBookmarkedStatus(bookmarkIds);

					// If ids are partially bookmarked, remove any already set then
					// re-add them all
					if (status === "partial") {
						get().actions.removeBookmarks(bookmarkIds);
						get().actions.addBookmarks(bookmarkIds);
					}
					// If ids are not bookmarked, add them
					else if (!status) {
						get().actions.addBookmarks(bookmarkIds);
					}
					// If ids are bookmarked, remove them
					else {
						get().actions.removeBookmarks(bookmarkIds);
					}
				},

				resetBookmarks: (bookmarks?: Bookmarks) => {
					set({ bookmarks: bookmarks || [] });
				},
			},
		}),
		{
			name: "bookmarks-server-action-storage",
			storage: SyncWithServerStorage,
			skipHydration: true,
		},
	),
);

/**
 * @returns All the user's bookmarks
 */
export const useBookmarks = () => useBookmarksStore((state) => state.bookmarks);

export const useBookmarksIsInitialLoadComplete = () =>
	useBookmarksStore((state) => state.isInitialLoadComplete);

/**
 * @returns The bookmark with a given ID, if it exists
 */
export const useBookmarkById = (bookmarkId: string) =>
	useBookmarksStore(
		(state) => state.bookmarks?.find((bm) => bm.id === bookmarkId),
	);

/**
 * @returns Count of the user's bookmarks
 */
export const useBookmarksCount = () =>
	useBookmarksStore((state) => state.bookmarks?.length ?? 0);

/**
 * @returns status  if the given bookmark IDs
 */
export const useIsBookmarkedStatus = (
	bookmarkIds?: WishlistIdentifierOrIdentifiers,
) =>
	useBookmarksStore((state) => {
		if (!bookmarkIds) {
			return false;
		}

		return state.actions.isBookmarkedStatus(bookmarkIds);
	});

/**
 * @returns action functions for this store
 */
export const useBookmarksActions = () =>
	useBookmarksStore((state) => state.actions);
