import { ResolvedSanityReferences } from "@/components/sanity/sanity.types";
import { assertSlug } from "./common";
import { assertDefined } from "./generic";

type ResolvedEventDate =
	ResolvedSanityReferences<Sanity.Default.Schema.EventDate>;

export function isEventDateMust(
	date?: ResolvedEventDate,
): date is ResolvedEventDate {
	return Boolean(date?.startDate) && Boolean(date?.endDate);
}

//TODO: This doesnt work!!
export function isVenueLinkMust(
	venue?: Sanity.Default.Schema.Venue,
): asserts venue is Sanity.Default.Schema.Venue {
	if (venue === undefined || venue === null) {
		throw new TypeError(`Venue cannot be ${typeof venue}`);
	}
}

export function isValidEvent(
	event:
		| ResolvedSanityReferences<Sanity.Default.Schema.Event>
		| Sanity.Default.Schema.Event
		| undefined,
): event is ResolvedSanityReferences<Sanity.Default.Schema.Event> {
	if (!event) {
		throw new TypeError(`Events cannot be ${typeof event}`);
	}

	return (
		event?.title !== undefined ||
		event?.slug !== undefined ||
		event?.asset !== undefined ||
		event?.recurring !== undefined ||
		event?.type !== undefined ||
		event?.artists !== undefined
	);
}

export function assertEventDateMust(
	date?: ResolvedEventDate,
): asserts date is ResolvedEventDate {
	if (!isEventDateMust(date)) {
		throw new TypeError(
			`Assertion to EventDate failed on ${JSON.stringify(date)}`,
		);
	}
}

export function assertEventDateMustArray(
	dates?: Array<ResolvedEventDate | undefined>,
): asserts dates is Array<ResolvedEventDate> {
	if (!dates) {
		throw new TypeError(`Event dates cannot be '${typeof dates}'`);
	}

	dates.forEach(assertEventDateMust);
}

export function assertEventOrSuperEventMustArray(
	events?: Array<ResolvedSanityReferences<Sanity.Default.Schema.Event>>,
): asserts events is Array<
	ResolvedSanityReferences<Sanity.Default.Schema.Event>
> {
	if (!events) {
		throw new TypeError(`Event cannot be '${typeof events}'`);
	}

	events.forEach(assertEventOrSuperEventHasRequiredFields);
}

export function assertEventOrSuperEventHasRequiredFields(
	event: ResolvedSanityReferences<Sanity.Default.Schema.Event>,
): asserts event is ResolvedSanityReferences<Sanity.Default.Schema.Event> {
	if (!event) {
		throw new TypeError("Event is 'null' or 'undefined'");
	}

	const { slug } = event;

	assertSlug(slug);

	assertDefined(
		event.title,
		`Event with slug ${slug.current} does not contain 'title'`,
	);
	assertDefined(
		event.type,
		`Event with slug ${slug.current} does not contain 'type'`,
	);
}

export function isSuperEvent(event?: { type?: string | null }) {
	if (event === null || event === undefined) {
		return false;
	}

	return event.type === "superEvent";
}

export function assertCollectionMust(
	collection?: Sanity.Default.Schema.Collection,
): asserts collection is Sanity.Default.Schema.Collection {
	if (!collection) {
		throw new TypeError("collection is 'null' or 'undefined'");
	}

	const { slug, name } = collection;

	assertSlug(slug);

	assertDefined(
		name,
		`collection with slug ${slug.current} does not contain 'name'`,
	);
}

export function assertCollectionArrayMust(
	collections: Array<Sanity.Default.Schema.Collection | undefined | null>,
): asserts collections is Array<Sanity.Default.Schema.Collection> {
	if (!collections) {
		throw new TypeError(`Collections cannot be '${typeof collections}'`);
	}

	collections.forEach(assertCollectionMust);
}
