"use client";

import { useEffect, useState } from "react";

import { usePathname } from "next/navigation";

import { X } from "lucide-react";

import { ResolvedAlerts } from "@/lib/sanity/fetchers/alerts/alerts";
import { isTruthy } from "@/lib/utils";
import PortableTextSection from "../portableTextSection/PortableTextSection";
import styles from "./Alerts.module.scss";

export default function Alerts({ alerts }: { alerts: ResolvedAlerts }) {
	const [isVisible, setIsVisible] = useState<boolean>(false);

	const pathname = usePathname();
	const isAuthPage = pathname.includes("/auth");

	useEffect(() => {
		if (alerts?.enableAlerts === true) {
			const alertsDelay = setTimeout(() => {
				setIsVisible(true);
			}, 1500);

			return () => clearTimeout(alertsDelay);
		}
	}, [alerts?.enableAlerts]);

	return (
		<>
			{isVisible && !isAuthPage && (
				<div
					className={styles.alertsContainer}
					role="alertdialog"
					aria-label="Site alerts"
				>
					<div className={styles.alertsInner}>
						<button
							type="button"
							onClick={() => setIsVisible(false)}
							className={styles.alertsButton}
							aria-label={isVisible ? "Close alerts" : "Open alerts"}
						>
							<X size={18} color="black" />
						</button>
						{isTruthy(alerts?.content) && (
							<PortableTextSection value={alerts?.content} />
						)}
					</div>
				</div>
			)}
		</>
	);
}
