import { NonNullableRecord, RecursiveMaybe } from "../types";
import { warnUnsafeAssertion } from "./helpers/logs";

/**
 * Unsafe assertion (no runtime type checks) to typed, non-null array
 *
 * @param array
 */
export function unsafeAssertsArrayOf<T>(
	array?: Array<T | undefined | null>,
	message?: string,
): asserts array is Array<T> {
	warnUnsafeAssertion(
		"Unsafe assertion of Array<T | undefined | null[]>",
		message,
	);
}

/**
 * Safe assertion for a single value not being null or undefined
 *
 * @param array
 */
export function assertsNonNullable<T>(
	value?: T | undefined | null,
	message?: string,
): asserts value is NonNullable<T> {
	if (value === undefined || value === null) {
		throw new TypeError(
			message || "Can not assert `undefined` or `null` values to NonNullable",
		);
	}
}

/**
 * Unsafe assertion (no runtime type checks) for a single value not being null
 * or undefined
 *
 * @param value
 */
export function unsafeAssertsNonNullable<T>(
	value?: T | undefined | null,
	message?: string,
): asserts value is NonNullable<T> {
	warnUnsafeAssertion("Unsafe assertion of NonNullable", message);
}

/**
 * Unsafe assertion (no runtime type checks) for a single value not being
 * null or undefined
 *
 * @param value
 */
export function unsafeAssertsMust<T>(
	value?: RecursiveMaybe<T>,
	message?: string,
): asserts value is NonNullableRecord<T> {
	warnUnsafeAssertion("Unsafe assertion of NonNullableRecord<T>", message);
}

/**
 * Asserts that a value is not undefined or null
 * @param value
 * @returns
 */
export function isDefined<T>(value?: T | null): value is T {
	// This could be performed with single equality, preferring to be explicit
	return value !== undefined && value !== null;
}

/**
 * Alias of `isDefined`, using Apollo codegen's funky parlance / generic Maybe<> type
 * @param value
 * @returns
 */
export function isMust<T>(value: T | undefined | null): value is T {
	return isDefined(value);
}

/**
 * Asserts that a value is not undefined or null
 * @param value
 * @returns
 */
export function assertDefined<T>(
	value?: T | null,
	message?: string,
): asserts value is T {
	if (!isDefined(value)) {
		throw new TypeError(
			message || `Assertion failed: ${typeof value} is not defined`,
		);
	}
}

/**
 * Alias of `isDefined`, using Apollo codegen's funky parlance / generic Maybe<> type
 * @param value
 * @returns
 */
export function assertMust<T>(
	value: T | undefined | null,
	message?: string,
): asserts value is T {
	assertDefined(value, message);
}
