import { stripUndefined } from '../helpers/normalise';

export function push({...event}: any): void {
  if (typeof window === 'undefined') {
    return;
  }

  if (window.dataLayer !== undefined) {
    window.dataLayer.push(stripUndefined(event));
  }
}
