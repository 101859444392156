"use client";

import { useEffect, useState } from "react";

import Link from "next/link";

import { useCartAndWebSessionWithExpirationsQuery } from "@/lib/clientSide/hooks/tessitura";
import { beforePhase } from "@/lib/helpers/phase";
import {
	CartExpirationStats,
	calculateCartExpirationStats,
} from "@/lib/tessitura";
import { pluralise } from "@/lib/text";
import { ProgramPhase } from "@/sanity/schemas/vendored/enums";
import { TICKETS_CART_SLUG, TICKETS_SLUG } from "../../lib/config/staticRoutes";
import { CartIcon } from "../icons/Icons";

import styles from "./CartLink.module.scss";

export function CartLink({ currentPhase }: { currentPhase?: ProgramPhase }) {
	const cartAndWebSessionWithExpirationsQuery =
		useCartAndWebSessionWithExpirationsQuery(
			{},
			{
				// Retry N times after a failure
				retry: 3,

				// `staleTime` sets how long in milliseconds until fetched data is
				// considered stale, after which time an action like query re-mount or
				// window focus will trigger a refetch
				staleTime: 60000,

				// `refetchInterval` sets the query to automatically refetch every N
				// milliseconds. Refetch will not happen if browser window is in the
				// background, unless `refetchIntervalInBackground` is set to true.
				refetchInterval: 60000,
			},
		);

	const [cartExpirationStats, setCartExpirationStats] =
		useState<CartExpirationStats | null>();

	const { webSessionWithExpirations, monaCart } =
		cartAndWebSessionWithExpirationsQuery.data || {};

	// Remove any previously fetched cart & web session data if we are
	// subsequently unable to re-fetch it. Without this work-around any prior
	// data will remain and seem valid forever (or until a manual page reload).
	useEffect(() => {
		if (cartAndWebSessionWithExpirationsQuery.isRefetchError) {
			cartAndWebSessionWithExpirationsQuery.remove();
		}
	}, [cartAndWebSessionWithExpirationsQuery]);

	useEffect(() => {
		function update() {
			if (webSessionWithExpirations && monaCart) {
				setCartExpirationStats(
					calculateCartExpirationStats(webSessionWithExpirations),
				);
			} else {
				setCartExpirationStats(null);
			}
		}

		// Calculate expiration stats on first render
		update();

		// Recalculate expiration stats every second
		const intervalId = setInterval(update, 1000);

		// Clean up the interval when the component unmounts
		return () => {
			clearInterval(intervalId);
		};
	}, [webSessionWithExpirations, monaCart, setCartExpirationStats]);

	const showCartLink = !beforePhase(currentPhase, ProgramPhase.GeneralOnsale);

	if (!showCartLink) {
		// Return an empty LI here to avoid breaking the nav layout
		return <li className={styles.cartEmpty}>&nbsp;</li>;
	}

	const lineItemCount =
		cartAndWebSessionWithExpirationsQuery.data?.monaCart?.products?.length;

	if (
		cartAndWebSessionWithExpirationsQuery.isLoading ||
		lineItemCount === 0 ||
		webSessionWithExpirations?.isCompleted ||
		!webSessionWithExpirations?.CartExpiration ||
		new Date(webSessionWithExpirations.CartExpiration) <= new Date()
	) {
		return (
			<li className={styles.cartEmpty}>
				<div className={styles.cartContainer}>
					<Link
						href={`/${TICKETS_SLUG}/${TICKETS_CART_SLUG}`}
						className={styles.cartDetails}
						aria-label="Your cart is currently empty"
					>
						<CartIcon />
					</Link>
				</div>
			</li>
		);
	}

	return (
		<li className={styles.cartActive}>
			<div className={styles.cartContainer}>
				<Link
					href={`/${TICKETS_SLUG}/${TICKETS_CART_SLUG}`}
					className={styles.cartLink}
					aria-label={`You currently have ${lineItemCount} ${pluralise(
						lineItemCount as number,
						"item",
					)} in your cart`}
				>
					<div className={styles.cartDetails}>
						{lineItemCount !== undefined && <span>{`(${lineItemCount})`}</span>}

						<CartIcon />
					</div>

					{cartExpirationStats && (
						<div className={styles.cartTimer}>
							<span
								className={styles.cartTimerLabel}
								role="timer"
								aria-live="polite"
							>
								{cartExpirationStats?.cartExpiresInMMSS}
							</span>
						</div>
					)}
				</Link>
			</div>
		</li>
	);
}
