"use client";

import { useEffect, useRef, useState } from "react";

import MobileDesktop from "@/components/utils/MobileDesktop";
import {
	FEAST_SLUG,
	FEAST_STALLHOLDERS_ID,
	WISHLIST_SLUG,
	WISHLIST_TITLE,
} from "@/lib/config/staticRoutes";
import { isPathWithinSlugs, slugsPath } from "@/lib/helpers/paths";
import { useBookmarksCount } from "@/lib/stores/bookmarksStore";
import { Bookmark } from "@/svg";
import { usePathname } from "next/navigation";
import NavigationItem from "../navigationItem/NavigationItem";

import styles from "./WishlistNavigationItem.module.scss";

export interface WishlistNavigationItemProps {}

const TRANSITION_DURATION_MS = 222;

export default function WishlistNavigationItem(
	props: WishlistNavigationItemProps,
) {
	/**
	 * Note that due to the flat structure of wishlist data, the count is the number of
	 * event performances, such that saving a lineup item or super event containing multiple
	 * performances will increase (or decrease if un-saving) the count by more than one.
	 *
	 * This is intentional behaviour since the alternative would cause the count to sometimes
	 * neither increase or decrease on event, free, ticketed, etc. pages where performances
	 * are saved individually and can be
	 */
	const count = useBookmarksCount();

	const [transition, setTransition] = useState<undefined | string>(undefined);
	const previousCount = useRef<number>(count);
	const pathname = usePathname();

	useEffect(() => {
		setTransition(count > previousCount.current ? styles.up : styles.down);
		previousCount.current = count;

		const timeout = window.setTimeout(
			() => setTransition(undefined),
			TRANSITION_DURATION_MS,
		);

		return () => clearTimeout(timeout);
	}, [count]);

	const itemStyle = transition || styles.item;

	// Append a #stallholder jump link when coming from feast
	const href = `${slugsPath(WISHLIST_SLUG)}${
		isPathWithinSlugs(pathname, FEAST_SLUG) ? `#${FEAST_STALLHOLDERS_ID}` : ""
	}`;

	return (
		<MobileDesktop>
			<div className={styles.mobileContainer}>
				<NavigationItem
					className={itemStyle}
					href={href}
					variant="icon"
					title="View you saved items"
				>
					<Bookmark className={styles.icon} />
					{count > 0 && <span className={styles.mobileCount}>{count}</span>}
				</NavigationItem>
			</div>
			<NavigationItem
				href={href}
				className={itemStyle}
				title="View you saved items"
			>
				<Bookmark className={styles.iconDesktop} />
				{WISHLIST_TITLE}{" "}
				{count > 0 && (
					<span className={styles.desktopCount}>&nbsp;({count})</span>
				)}
			</NavigationItem>
		</MobileDesktop>
	);
}
