import { ProgramPhase } from "@/sanity/schemas/vendored/enums";
import { Phase } from "@/types/Phase";

export function isProgramPhaseKey(
	phase?: string | null,
): phase is keyof typeof ProgramPhase {
	return !!phase && Object.keys(ProgramPhase).includes(phase);
}

export function assertProgramPhaseKey(
	phase?: string | null | undefined,
): asserts phase is keyof typeof ProgramPhase {
	if (!isProgramPhaseKey(phase)) {
		throw new TypeError(
			`Phase '${phase}' must match one of ${Object.keys(ProgramPhase).join(
				", ",
			)}`,
		);
	}
}

export function assertProgramPhase(
	phase?: Phase | null | undefined,
): asserts phase is Phase {
	if (!phase) {
		throw new TypeError(
			`Phase must be of type Phase, but found ${typeof phase}`,
		);
	}
}
