"use client";

import React, { useState } from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { FetchError } from "@/lib/betterFetch";

// From https://github.com/2manoj1/poc-nextjs-13-react-query
// via https://github.com/TanStack/query/issues/4933
export const ReactQueryProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [queryClient] = useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						retry: (failureCount, error) => {
							// Do not retry 404 fetch errors
							if (error instanceof FetchError && error.info?.status === 404) {
								return false;
							}
							return true;
						},
					},
				},
			}),
	);

	return (
		<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
	);
};
