"use client";

import { Suspense } from "react";

import { useStoreTessituraSourceIdInCookie } from "@/lib/clientSide/hooks/tessitura";

/**
 * Helper component to save a Tessitura tracking source ID integer from query
 * string parameters – `promo` or `source` – to a browser cookie so it will be
 * provided to API requests.
 *
 * This must be included at top level of site, ideally root layout.
 */
export function StoreTessituraSourceIdInCookie() {
	// Wrap actual work in <Suspense> to prevent internal `useSearchParams()`
	// call from "deopting" ancestor components & pages into client-side
	// rendering: https://nextjs.org/docs/messages/deopted-into-client-rendering
	return (
		<>
			<Suspense>
				<InternalComponent />
			</Suspense>
		</>
	);
}

function InternalComponent() {
	useStoreTessituraSourceIdInCookie();

	// This component is just a helper, it doesn't render anything
	return null;
}
