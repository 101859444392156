import type React from "react";
import styles from "./NavigationItemGroup.module.scss";

export interface NavigationItemGroupProps {
	children: [React.ReactNode, ...Array<React.ReactNode>];
}

export default function NavigationItemGroup({
	children,
}: NavigationItemGroupProps) {
	const [primary, ...secondary] = children;

	if (!secondary.length) {
		return <>{primary}</>;
	}

	return (
		<li className={styles.container}>
			<ul className={styles.list}>
				{primary}
				{secondary}
			</ul>
		</li>
	);
}
