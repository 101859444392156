import { isDefined } from "../sanity/assertions";

/**
 * Normalise a dreaded Maybe<T> type by replacing `null` with `undefined`
 *
 * @param withNull
 * @returns
 */
export function stripNull<T>(withNull?: T): NonNullable<T> | undefined {
	if (withNull === null) {
		return undefined;
	}

	return withNull;
}

/**
 * Normalise a dreaded Maybe<T> type by replacing `null` with `undefined`
 *
 * @param withNull
 * @returns
 */
export function stripNullArray<T>(
	withNull?: Array<T | undefined | null>,
): Array<NonNullable<T>> | undefined {
	if (!withNull) {
		return undefined;
	}

	// TODO: remove unnecessary map with a better generic filter
	return withNull.map(stripNull).filter(isDefined);
}

export function stripSoftHyphens(title?: string): string | undefined {
	return title?.replace(/­/g, "");
}

export function stripUndefined(data: any) {
	return Object.fromEntries(
		Object.entries(data).filter(([_key, value]) => value !== undefined),
	);
}
