"use client";

import { clientEnv } from "@/lib/clientSide/env.client.mjs";
import { useTrackVirtualPageViewEffect } from "@/lib/clientSide/tracking";

/**
 * Initialise Google Tag Manager (GTM) on initial page load, invoke in _app.
 *
 * Plain GTM isn't enough to fire page load events on client-side navigation,
 * for that we rely on `useTrackVirtualPageViewEffect()`
 */
export function GoogleTrackingComponent() {
	// Initialise Google Tag Manager and track virtual page views
	useTrackVirtualPageViewEffect();

	return (
		<>
			<script
				dangerouslySetInnerHTML={{
					__html: `
					(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','${clientEnv.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');
				`,
				}}
			/>

			<noscript
				dangerouslySetInnerHTML={{
					__html: `
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=${clientEnv.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          />
        `,
				}}
			/>
		</>
	);
}
